<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <title>email-action-unread</title>
    <path
      stroke="currentColor"
      d="M23.888,5.832a.182.182,0,0,0-.2.039l-9.747,9.745a2.75,2.75,0,0,1-3.888,0L.31,5.871a.18.18,0,0,0-.2-.039A.182.182,0,0,0,0,6V18a2,2,0,0,0,2,2H22a2,2,0,0,0,2-2V6A.181.181,0,0,0,23.888,5.832Z"
    />
    <path
      stroke="currentColor"
      d="M11.115,14.556a1.252,1.252,0,0,0,1.768,0L22.569,4.87a.5.5,0,0,0,.121-.511C22.58,4.03,22.274,4,22,4H2c-.275,0-.583.03-.691.359a.5.5,0,0,0,.121.511Z"
    />
  </svg>
</template>
